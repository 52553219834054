<template>
  <div class="container">
    <van-loading
      class="loading"
      color="#9d1d22"
      text-color="#9d1d22"
      v-if="loading"
      size="24px"
      >加载中...</van-loading
    >

    <div class="qinfo" v-if="loading == false">
      <h2 style="text-align: center">{{ exam.title }}</h2>
      <!--考试前说明-->
      <div
        v-show="!showquestion"
        class="card"
        style="height: calc(100% - 90px); overflow-y: auto"
      >
        <div class="acontent" v-html="exam.examPaper.detailsHtml"></div>
        <div v-if="exam.examPaper.timeLength">
          考试时长{{ exam.examPaper.timeLength }}分钟
        </div>
        <div>考题数量{{ exam.questionList.length }} 题</div>
        <div style="color: red" v-if="!exam.hasStart">
          考试未开始，敬请期待！
        </div>
        <div style="color: red" v-if="exam.hasEnd">
          考试已结束，期待下次参与
        </div>

        <div
          class="submit"
          style="position: fixed; bottom: 0; left: 0; width: 100%"
        >
          <van-row>
            <van-col span="8" offset="8"
              ><van-button
                style="margin: 5px"
                round
                block
                :disabled="submitDisabled || !exam.hasStart || exam.hasEnd"
                type="primary"
                native-type="submit"
                @click="onStart()"
              >
                开始答题
              </van-button></van-col
            >
          </van-row>
        </div>
      </div>
      <!--考试中-->
      <div v-show="showquestion" style="height: calc(100% - 140px)">
        <van-row>
          <van-col span="8" v-if="!isLimitTime"> </van-col>
          <van-col
            v-if="isLimitTime"
            span="4"
            style="text-align: center; line-height: 45px; font-size: 18px"
            >计时:</van-col
          >
          <van-col
            v-if="isLimitTime"
            span="4"
            style="text-align: center; line-height: 45px; font-size: 18px"
            ><van-count-down
              :time="time"
              ref="countDown"
              :auto-start="false"
              format="mm:ss"
              style="margin-top: 11px"
              @finish="timeOver"
            >
              <template #default="timeData">
                <span class="block">{{ timeData.minutes }}</span>
                <span class="colon">:</span>
                <span class="block">{{ timeData.seconds }}</span>
              </template>
            </van-count-down></van-col
          >
          <van-col
            span="11"
            style="text-align: center; line-height: 45px; font-size: 18px"
          ></van-col>
          <van-col span="5">
            <van-button
              style="float: right; margin: 5px"
              :disabled="submitDisabled"
              type="primary"
              native-type="submit"
              block
              @click="onsubmit(true)"
            >
              交 &nbsp;卷 &nbsp;
            </van-button>
          </van-col>
        </van-row>

        <div style="height: 100%; overflow-y: auto" class="card">
          <div
            v-for="(item, index) in exam.questionList"
            :key="item.id.toString()"
            :id="item.id.toString()"
          >
            <div
              v-show="showIndex == index"
              class="questionList"
              :style="
                item.haserror === true
                  ? 'border: 2px solid red;'
                  : 'border: 2px solid rgba(255,255,255,0);'
              "
            >
              <div>
                <div class="question">
                  <h2>
                    {{ index + 1 }}、{{ item.title }}
                    {{
                      item.typeId == "5" && item.answerText > 0
                        ? "(" + item.answerText + "分)"
                        : ""
                    }}
                  </h2>
                </div>
                <div v-if="item.typeId == 1" class="option">
                  <van-radio-group
                    v-model="item.checked"
                    @change="checkquestion(index)"
                  >
                    <van-radio
                      :name="option.id.toString()"
                      :disabled="submitDisabled"
                      v-for="(option, oindex) in item.optionList"
                      :key="option.id.toString()"
                      >{{ GetOptionTitle(option.title, oindex) }}
                      <van-image
                        v-if="
                          option.imageUrl != undefined &&
                          option.imageUrl.length > 10
                        "
                        :src="option.imageUrl"
                      >
                        <template v-slot:error>图片加载失败</template>
                      </van-image>
                    </van-radio>
                  </van-radio-group>
                </div>
                <div v-if="item.typeId == 2" class="option">
                  <van-checkbox-group
                    v-model="item.checked"
                    @change="checkquestion(index)"
                  >
                    <van-checkbox
                      shape="square"
                      :disabled="submitDisabled"
                      :name="option.id.toString()"
                      v-for="(option, oindex) in item.optionList"
                      :key="option.id.toString()"
                      >{{ GetOptionTitle(option.title, oindex) }}
                      <van-image
                        v-if="
                          option.imageUrl != undefined &&
                          option.imageUrl.length > 10
                        "
                        :src="option.imageUrl"
                      >
                        <template v-slot:error>图片加载失败</template>
                      </van-image>
                    </van-checkbox>
                  </van-checkbox-group>
                </div>
                <!-- 文本 -->
                <div v-if="item.typeId == 3" class="questionInput">
                  <van-field
                    size="large"
                    v-model="item.answerText"
                    :disabled="submitDisabled"
                    :error-message="item.errormessage"
                    @input="checkInput(index)"
                  />
                </div>
                <!-- 整数 -->
                <div v-if="item.typeId == 32" class="questionInput">
                  <van-field
                    size="large"
                    type="digit"
                    v-model="item.answerText"
                    :disabled="submitDisabled"
                    :error-message="item.errormessage"
                    @input="checkInput(index)"
                  />
                </div>
                <!-- 数字 -->
                <div v-if="item.typeId == 33" class="questionInput">
                  <van-field
                    size="large"
                    type="number"
                    v-model="item.answerText"
                    :disabled="submitDisabled"
                    :error-message="item.errormessage"
                    @input="checkInput(index)"
                  />
                </div>
                <!-- 手机号 -->
                <div v-if="item.typeId == 31" class="option">
                  <van-field
                    size="large"
                    type="tel"
                    maxlength="11"
                    v-model="item.answerText"
                    :disabled="submitDisabled"
                    :error-message="item.errormessage"
                    @input="checkInputPhone(index)"
                  />
                </div>
                <!-- 备注 -->
                <div v-if="item.typeId == 34" class="option">
                  <div
                    v-show="item.errormessage.length > 0"
                    style="margin-left: 15px"
                    class="van-field__error-message"
                  >
                    {{ item.errormessage }}
                  </div>

                  <textarea
                    class="texta"
                    rows="2"
                    autosize
                    v-model="item.answerText"
                    :disabled="submitDisabled"
                    :error-message="item.errormessage"
                    @input="checkInput(index)"
                  ></textarea>
                </div>
                <!-- 日期 -->
                <div v-if="item.typeId == 4" class="option">
                  <van-cell
                    color="#646566"
                    title="日期"
                    :disabled="submitDisabled"
                    :value="item.answerText"
                    @click="item.show = true"
                  />
                  <van-calendar
                    v-model="item.show"
                    @confirm="onConfirm($event, index)"
                  />
                </div>
                <!-- 评分 -->
                <div v-if="item.typeId == 5" class="option">
                  <van-rate
                    v-model="item.answerText"
                    @change="onChange($event, index)"
                    :disabled="submitDisabled"
                    count="10"
                    color="#ffd21e"
                    void-icon="star"
                    void-color="#eee"
                    size="25px"
                  />
                </div>
                <!--上传文件-->
                <div v-if="item.typeId == 6">
                  <van-field label="上传图片">
                    <template #input>
                      <van-uploader
                        v-model="item.filelist"
                        :disabled="submitDisabled"
                        multiple
                        :name="index"
                        :max-count="3"
                        :after-read="afterReadFile"
                      />
                    </template>
                  </van-field>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div style="position: fixed; bottom: 0; left: 0; width: 100%">
          <van-row>
            <van-col span="5"
              ><van-button
                type="info"
                style="margin: 5px"
                @click="toPre()"
                :disabled="showIndex == 0 || submitDisabled"
                block
                >上一题</van-button
              ></van-col
            >
            <van-col
              span="14"
              style="text-align: center; line-height: 45px; font-size: 18px"
              >共{{ exam.questionList.length }}题,已做{{
                this.getHasAnswer
              }}题</van-col
            >
            <van-col span="5"
              ><van-button
                v-show="showIndex != exam.questionList.length - 1"
                type="info"
                style="float: right; margin: 5px"
                :disabled="
                  showIndex == exam.questionList.length - 1 || submitDisabled
                "
                block
                @click="toNext()"
                >下一题</van-button
              >
              <van-button
                style="float: right; margin: 5px"
                v-show="showIndex == exam.questionList.length - 1"
                :disabled="submitDisabled"
                type="primary"
                native-type="submit"
                block
                @click="onsubmit(true)"
              >
                交 &nbsp;卷 &nbsp;
              </van-button>
            </van-col>
          </van-row>
        </div>

        <!-- 提交 -->
        <div class="submit">
          <div
            class="submitSuccess"
            id="posterImage"
            v-show="exam.showType == 1"
            @click="toHome"
          >
            {{ showMessage }}
          </div>

          <div class="submitError" v-show="exam.showType > 1" @click="toHome">
            {{ showMessage }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Toast, Dialog } from "vant";
import { GetExamQuestionForUser, AddAnswer } from "@/api/exam";

import { uploadfileplus } from "@/api/file";

export default {
  data() {
    return {
      show: false,
      loading: true,
      exam: {},
      hasQuestionnaire: true,
      phoneerror: "",
      usernameerror: "",
      submitDisabled: false,
      showMessage: "",
      showTypeInfo: "",
      date: "",
      answer: {
        examId: "",
        examPaperId: "",
        parentDeviceId: "00000000-0000-0000-0000-000000000000",
        sourceType: 1,
        deviceId: "",
        userCode: "",
        userName: "",
        phone: "",
        position: "",
        province: "",
        city: "",
        county: "",
        address: "",
        questionList: [],
      },
      poster: [],
      isLimitTime: false,
      time: 60 * 1000,
      beginTime: new Date(),
      showIndex: 0, //显示第几题
      showquestion: false,
    };
  },
  computed: {
    // 计算属性的 getter
    getHasAnswer: function () {
      return this.exam.questionList.filter((x) => x.hasanswer).length;
    },
  },

  created() {
    this.initData();
  },

  methods: {
    /***题型：
'单选题': 1 
'多选题': 2 
'文本': 3 
'日期': 4 
'评分': 5 
'手机号': 31 
'整数': 32 
'数字': 33 
'文本备注': 34 
 */
    async initData() {
      let examId = this.$route.query.q;

      //let userId = ""; //this.$route.query.u;
      this.submitDisabled = false;
      this.answer.examId = examId;

      var quResult = await GetExamQuestionForUser(examId);
      console.log(quResult);
      if (quResult.data.success) {
        var myexam = quResult.data.data;
        for (let index = 0; index < myexam.questionList.length; index++) {
          //初始化附加属性值
          myexam.questionList[index].haserror = false;
          myexam.questionList[index].hasanswer = false;
          myexam.questionList[index].errormessage = "";
          myexam.questionList[index].filelist = [];
          if (myexam.questionList[index].typeId == 5) {
            myexam.questionList[index].answerText = 0;
          } else {
            myexam.questionList[index].answerText = "";
          }

          myexam.questionList[index].show = false;
        }
        this.exam = myexam;
        this.answer.examPaperId = myexam.examPaperId;
      } else {
        this.hasQuestionnaire = false;
        this.showMessage = quResult.data.errorMessage;
        Toast(quResult.data.errorMessage);
      }
      if (this.exam.showType > 1) {
        this.submitDisabled = true;
        this.hasQuestionnaire = false;
      }
      if (this.exam.showType == 2) {
        this.showMessage =
          this.exam.title + "考试未开始，敬请期待！,点击返回KM首页";
      }
      if (this.exam.showType == 3) {
        this.showMessage =
          this.exam.title + "考试已结束，期待您下次参与! 点击返回KM首页";
      }
      if (this.exam.showType == 4) {
        this.showMessage =
          "您已完成本次考试，感谢您的帮助与支持! 点击返回KM首页";
        this.showTypeInfo = this.exam.showTypeInfo;
      }

      this.loading = false;
      if (
        this.exam.examPaper?.timeLength &&
        this.exam.examPaper?.timeLength > 0
      ) {
        this.time = this.exam.examPaper?.timeLength * 60 * 1000; //转换为毫秒
        this.isLimitTime = true;
      }
    },

    afterReadFile: async function (files, detail) {
      Toast("文件上传中");
      let formData = new FormData();
      formData.append("file", files.file);
      let aresult = await uploadfileplus(formData, "Eaxm");

      let url = aresult.data; // Get url from response
      let index = detail.name;
      this.exam.questionList[index].filelist = this.exam.questionList[
        index
      ].filelist.filter(function (item) {
        return item.url != undefined && item.url.indexOf("http") >= 0;
      });
      this.exam.questionList[index].filelist.push({
        url: url,
        imageFit: "contain",
        previewSize: 80,
      });

      Toast("文件上传成功");
    },
    checkquestion(index) {
      this.exam.questionList[index].haserror = false;
      this.exam.questionList[index].hasanswer = true;
    },
    checkInput(index) {
      this.exam.questionList[index].haserror = false;
      this.exam.questionList[index].hasanswer = true;
    },
    checkInputPhone(index) {
      if (this.exam.questionList[index].answerText.length < 11) {
        this.exam.questionList[index].errormessage = "";
        return;
      }
      var phonereg = /^[1][3,4,5,7,8,9][0-9]{9}$/;
      if (!phonereg.test(this.exam.questionList[index].answerText)) {
        this.exam.questionList[index].errormessage = "请填写有效的手机号码";
        this.exam.questionList[index].haserror = true;
      } else {
        this.exam.questionList[index].errormessage = "";
        this.exam.questionList[index].haserror = false;
        this.exam.questionList[index].hasanswer = true;
      }
    },
    toHome() {
      window.location.replace(window.location.origin);
    },
    onStart() {
      this.showquestion = true;
      this.beginTime = new Date();
      if (this.isLimitTime) {
        this.$refs.countDown.start();
      }
    },
    timeOver() {
      Dialog.alert({
        title: "考试结束",
        message: "考试时间用尽，自动交卷",
      }).then(() => {
        // on close
        this.submitDisabled = true;
      });
      //交卷
      this.onsubmit(false);
    },
    toBegining() {
      this.showquestion = false;
    },
    toPre() {
      if (this.showIndex > 0) {
        this.showIndex = this.showIndex - 1;
      }
    },
    toNext() {
      if (this.showIndex >= 0) {
        this.showIndex = this.showIndex + 1;
      }
    },
    async onsubmit(ischeck) {
      this.submitDisabled = true;
      var checked = true;
      var itemerror = "";
      var phonereg = /^[1][3,4,5,7,8,9][0-9]{9}$/;
      //是否检查未作答
      if (ischeck) {
        for (let index = 0; index < this.exam.questionList.length; index++) {
          // element是所有信息

          const element = this.exam.questionList[index];
          if (element.typeId == 1 && element.checked == undefined) {
            element.haserror = true;
          } else if (
            element.typeId == 2 &&
            (element.checked == undefined || element.checked.length == 0)
          ) {
            element.haserror = true;
          } else if (
            (element.typeId == 4 ||
              element.typeId == 3 ||
              (element.typeId >= 31 && element.typeId <= 39)) &&
            element.answerText == ""
          ) {
            element.haserror = true;
          } else if (element.typeId == 5 && element.answerText == 0) {
            element.haserror = true;
          }

          if (element.typeId == 31 && element.isMust) {
            if (!phonereg.test(element.answerText)) {
              element.errormessage = "请填写有效的手机号码";
              element.haserror = true;
            } else {
              element.haserror = false;
              element.errormessage = "";
            }
          }

          // str.replace(/(^\s*)|(\s*$)/g, "")
          if (
            (element.typeId == 3 ||
              (element.typeId >= 31 && element.typeId <= 39)) &&
            element.isMust
          ) {
            if (
              (element.minLength || element.minLength > 0) &&
              element.answerText.replace(/(^\s*)|(\s*$)/g, "").length <
                element.minLength
            ) {
              element.errormessage =
                "最小长度是" + element.minLength + "个字符";
              element.haserror = true;
            } else {
              element.errormessage = "";
            }
          }

          //第一个错误,页面会滚动到
          if (checked && element.haserror) {
            itemerror =
              index +
              1 +
              "、" +
              element.title +
              (element.typeId <= 2 ? " 请选择" : " 请填写");
            checked = false;
            Toast(itemerror);
            this.showIndex = index;
            document.getElementById(element.id).scrollIntoView();
          }
        }

        if (!checked) {
          this.submitDisabled = false;

          return;
        }
      }
      //剩余时间 毫秒
      var now_time = new Date();
      var difftime = parseInt((now_time - this.beginTime) / 1000); //秒
      this.answer.takeTime = difftime;

      //answerItem =答案
      this.answer.questionList = [];

      for (let index = 0; index < this.exam.questionList.length; index++) {
        const question = this.exam.questionList[index];
        var answerItem = {};
        if (question.typeId == 1) {
          answerItem = {
            id: question.id,
            typeId: question.typeId,
            optionValue: question.checked?.toString(),
            answerText: "",
          };
        } else if (question.typeId == 6) {
          let urls = "";
          question.filelist.forEach((element) => {
            if (element.url != undefined) {
              urls += element.url + " ";
            }
          });
          urls = urls.trimEnd();
          answerItem = {
            id: question.id,
            typeId: question.typeId,
            optionValue: "",
            answerText: urls,
          };
        } else if (question.typeId == 2) {
          answerItem = {
            id: question.id,
            typeId: question.typeId,
            optionValue: question.checked?.toString(),
            answerText: "",
          };
        } else if (question.typeId >= 3 && question.typeId <= 50) {
          answerItem = {
            id: question.id,
            typeId: question.typeId,
            optionValue: "",
            answerText: question.answerText.toString(),
          };
        }

        this.answer.questionList.push(answerItem);
      }
      try {
        this.$refs.countDown?.pause();
      } catch (error) {
        console.log(error);
      }
      
      try {
        var areulst = await AddAnswer(this.answer);
        if (areulst.data.success) {
          if (
            areulst.data.scoreMessage !== undefined &&
            areulst.data.scoreMessage != ""
          ) {
            Toast({
              message: areulst.data.scoreMessage,
              icon: "/image/signin_point_add.png",
            });
          } else {
            Toast("考试提交成功");
          }
          if (areulst.data.data.isPass && areulst.data.data.certificateId) {
            Dialog.alert({
              message: "恭喜您考试通过，并获取得证书！",
              confirmButtonText: "查看证书",
            }).then(() => {
              //调转到考试结果
              this.$router.replace({
                path: "/user/mycertificatedetail",
                query: { para: areulst.data.data.certificateId },
              });
            });

            // //调转到考试结果
            // this.$router.replace({
            //   path: "/exam/answerDetail",
            //   query: { para: areulst.data.data.certificateId },
            // });
          }
          if (areulst.data.data.answerID) {
            //调转到考试结果
            this.$router.replace({
              path: "/exam/answerDetail",
              query: { para: areulst.data.data.answerID },
            });
          }

          this.showMessage =
            "提交成功，您已完成本次考试，感谢您的帮助与支持。点击返回KM首页";
        } else {
          Toast(areulst.data.errorMessage);
          this.submitDisabled = false; //exam.showType
          this.exam.showType = 500;
          this.showMessage = "考试提交失败";
          if (areulst.data.errorCode == "PhoneError") {
            this.showMessage = areulst.data.errorMessage;
          }
        }
      } catch (error) {
        this.submitDisabled = false;
      }
    },

    inputUserName() {
      if (this.answer.userName.length < 1) {
        return;
      }
      if (this.answer.userName == "") {
        this.usernameerror = "请填写姓名";
      } else {
        this.usernameerror = "";
      }
    },

    GetOptionTitle(title, index) {
      var optionIndex = [
        "A",
        "B",
        "C",
        "D",
        "E",
        "F",
        "G",
        "H",
        "I",
        "J",
        "K",
        "L",
        "M",
        "N",
        "O",
        "P",
        "Q",
        "R",
        "S",
        "T",
        "U",
        "V",
        "W",
        "X",
        "Y",
        "Z",
      ];
      return optionIndex[index] + "、" + title;
    },
    onChange(value, index) {
      Toast("评分：" + value + "分");
      this.exam.questionList[index].haserror = false;
      this.exam.questionList[index].answerText = value;
    },
    formatDate(date) {
      var month = date.getMonth() + 1;
      if (month < 10) {
        month = "0" + month;
      }
      var day = date.getDate();
      if (day < 10) {
        day = "0" + day;
      }
      return `${date.getFullYear()}-${month}-${day}`;
    },
    onConfirm(date, index) {
      this.exam.questionList[index].haserror = false;
      this.exam.questionList[index].answerText = this.formatDate(date);
      this.exam.questionList[index].show = false;
    },
  },
};
</script>

<style lang="less" scoped>
.loading {
  text-align: center;
}
.container {
  height: 100%;
  overflow-y: auto;
  box-sizing: border-box;
  background-color: #e4dddd;

  //padding: 0px 10px 20px 10px;
}
.qinfo {
  height: 100%;
}
.questionList {
  margin: 0px 10px 0px 10px;
}
.questionInput {
  // height: 75px;
  margin: 0px 5px 5px 5px;
  font-size: 16px;
}
.question {
  margin: 20px 10px 20px 10px;
}
.card {
  background-color: #fff;
  border-radius: 12px;
  box-shadow: 0 8px 12px #ebedf0;
}
.colon {
  display: inline-block;
  margin: 0 4px;
  color: #ee0a24;
}
.block {
  display: inline-block;
  width: 22px;
  color: #fff;
  font-size: 12px;
  text-align: center;
  background-color: #ee0a24;
}

.van-checkbox {
  margin: 10px 0px 10px 5px;
  border: 1px solid #d8d8d8;
  padding: 5px;
}
.van-checkbox[aria-checked="true"] {
  border: 1px solid #2672ff;
  padding: 5px;
}

.van-radio {
  margin: 10px 0px 10px 5px;
  border: 1px solid #d8d8d8;
  padding: 5px;
}
.van-radio[aria-checked="true"] {
  border: 1px solid #2672ff;
  padding: 5px;
}

.submit {
  padding: 0px;
}
.submitInfo {
  padding-top: 10px;
  font-size: 18px;
  text-align: center;
}
.submitSuccess {
  color: green;
  padding-top: 10px;
  font-size: 18px;
  text-align: center;
}
.submitError {
  color: red;
  padding-top: 10px;
  font-size: 18px;
  text-align: center;
}
.posterInfo {
  padding-top: 10px;
  font-size: 15px;
  text-align: center;
}
.acontent {
  /deep/  p {
    padding: revert;
    margin: revert;
}
  /deep/ div {
    margin: 2px 10px;
  }

  /deep/ img {
    max-width: 100% !important;
    display: block;
    border: 0;
  }
  /deep/ video {
    max-width: 100% !important;
  }
}
.my-swipe .van-swipe-item {
  //height: 150px;
  color: #fff;
  font-size: 20px;
  text-align: center;
  //background-color: #39a9ed;
}

.my-swipe img {
  display: inline-block;
  width: 75%;
  height: 10%;
}
.texta {
  background: 0%;
  width: 100%;
  height: 63px;
  margin-left: 5px;
  border: 1px solid #d8d8d8;
}
.van-cell {
  border: 1px solid #d8d8d8;
  background: 0%;
  margin-left: 5px;
  color: #646566;
}
</style>